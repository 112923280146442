import * as React from "react"
import { graphql, Link } from "gatsby"
import { Header, Layout } from '../components'

class Articles extends React.Component {
  render() {

    const {
      data: { news, pages }
    } = this.props

    return (
      <Layout>
        <div>
          <Header location={this.props.location} />
          <div style={{ paddingTop: '3.2rem' }}>
            <h2 style={{ fontSize: '3rem' }}>Actualités</h2>
            <div>
              {
                news.nodes.length > 0 && (
                  <ul>
                    {
                      news.nodes.map((item, index) => {
                        return (
                          <li key={`news-item-${index}`}>
                            <Link to={`/articles/${item.uid}`}>
                              {item.data.title.text}
                            </Link>
                          </li>
                        )
                      })
                    }
                  </ul>
                )
              }

              <h2 style={{ fontSize: '3rem', paddingTop: '2.2rem' }}>Pages</h2>
              {
                pages.nodes.length > 0 && (
                  <ul>
                    {
                      pages.nodes.map((item, index) => {
                        return (
                          <li key={`pages-item-${index}`}>
                            <Link to={`/articles/${item.uid}`}>
                              {item.data.title.text}
                            </Link>
                          </li>
                        )
                      })
                    }
                  </ul>
                )
              }
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Articles;

export const articleQuery = graphql`
  query articlesList {

    news: allPrismicNews(
      sort: {order: DESC, fields: first_publication_date}
      filter: {data: {archive: {eq: false}, status: {eq: true}}}
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          publicdate
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }

    pages: allPrismicPages(
      sort: {order: DESC, fields: first_publication_date}
      filter: {data: {archive: {eq: false}, status: {eq: true}}}
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
        }
      }
    }
  }
`